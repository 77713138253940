import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { hidePopupAction } from 'src/app/store/actions/popup.actions';
import { selectFriendly } from 'src/app/store/selectors/result-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';

@Component({
	selector: 'app-thank-you-callback',
	templateUrl: './thank-you-callback.component.html',
	styleUrls: ['./thank-you-callback.component.scss'],
})
export class ThankYouCallbackComponent implements OnInit {
	data: any;
	friendlyID;
	constructor(private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.store
			.select(selectFriendly)
			.pipe(first())
			.subscribe((data) => {
				this.friendlyID = data;
			});
	}

	handleReturnOffer() {
		this.store.dispatch(hidePopupAction());
	}
}
